
.add-room{
  height:30px;
  color:$brand-color-2;
  background-color: lighten($brand-color-3,10);
  font-size:$font-size-h4;
  padding:5px 5px 5px 5px;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 10px;
    padding: 5px 5px 5px 5px;
  &__heading{
    //float:left;
    margin-right:10px;
  }
  &__link{
	  cursor:pointer;
    //float:right;
  }
}
  .room{
    @include clearfix;
    background-color:lighten($brand-color-3,10);
    height:30px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom:10px;
    padding: 5px 5px 5px 5px;
    &__number{
      float:left;
      color:$brand-color-1;
      font-size:$font-size-h4;
    }
    &__close{
      float:right;
      color:$brand-color-5;
      cursor:pointer;
    }
  }
  .passenger-info{
    @include clearfix;
    margin-bottom:10px;
    &__adult{
      float: left;
      padding: 0 .9375rem;
      width: 25%;
    }
    &__children{
    @include make-one-fourth;
    }
    &__age{
      @include make-one-half;
      padding-left: 0px;
      padding-right: 0px;
      &_heading{
        color:$brand-color-1;
        margin-left:15px;
      }
      &_selector{
          @include make-one-half;



      //@include make-one-fourth;
      }
    }
    
    &__pax-container {
      float: left;
      padding: 0 .9375rem;
      width: 30%;

      &_age {
        margin-top: 0.5rem;

        .age {
          &__heading {
            color: $brand-color-1;
          }
          &__selector {
            float: left;
            padding-right: 0.9375rem;
            width: 45%;

            select {
              padding: .5rem .25rem;
            }
          }
          &__selector:nth-child(4) {
            margin-top: 0.5rem;
          }
        }
      }
    }
    
  }
   .age__select{
     @include make-one-fourth;
   }

  .room-info{
    @include clearfix;
    margin-bottom:10px;
    &__type{
      @include make-one-fourth;
    }
    &__extra-bed{
      @include make-three-fourth;
      padding-top: 35px;
    }
  }
  .extra-bed__checkbox{
    height:20px;
    width:20px;
  }

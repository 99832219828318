.pax-info {
    padding-bottom: $base-padding/3;
    &__row {
        @include clearfix;
        padding-top: $base-padding/3;
        padding-bottom: $base-padding/3;
    }
    &__traveller,
    &__title,
    &__fname,
    &__lname,
    &__dob{
        float: left;
        padding: $base-padding/10;
        min-height: 1px;
    }
    &__traveller,
    &__title {
        width: 20%;
    }
    &__fname {
        .autosuggestion {
            &__item {
                &_title {
                    padding-left: 5px;
                    padding-right: 5px;
                    padding-top: 5px;
                }
            }
        }
        .search-location {
            &_autosuggestion {
                .itemcontainer {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
    &__fname,
    &__lname {
        width: 20%;
    }
    &__dob {
        width: 20%;
    }
    &__traveller {
        color: $brand-color-2;
        text-transform: uppercase;
        padding-top: $base-padding/2;
        padding-left: 20px;
        text-align:center;
    }
    &__show-as-inf{
        font-size:$font-size-h7;
    }
    .has-error{
		border: 2px solid red;
	}
    
    &__dob {
        label {
            display: none;
        }
        .input-group .input-group-btn {
            display: table-cell;
            >button {
                padding: 0.1rem 0.80rem;
            }
            >button:disabled {
                cursor: not-allowed;
            }
        }
    }
     &__mobile{
     	width: 21%;
     	&_country-code {
         	width: 21%;
         	float: left;
        }
     	&_num {
     		width: 75%;
     		float: right;
     	}
     }
    input[type="text"] {
        width: 100%;
        padding: $base-padding/10;
        background: none;
        // font-size: 0.875rem;
        vertical-align: middle;
        line-height: normal;
        background-color: $input-bgcolor;
        border: 1px solid;
        border-color: $input-border-color;
        max-height: 20px;
    }
    select {
        width: 100%;
        padding: 0 $base-padding/10;
        background: none;
        // font-size: 0.875rem;
        vertical-align: middle;
        line-height: normal;
        max-height: 20px;
    }
    .input-group-btn .btn {
        padding: 0;
        font-size: 11px;
    }
    input[disabled] {
    	cursor: not-allowed;
    }
}
.pax-info__extra {
    .flight__pax-info_header {
    	background-color: $brand-color-4;
        > .pax-info__row {
            text-transform: uppercase;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        ul > li {
            float: left;
            width: 15%;
            text-align: center;
            font-size: $font-size-h6;
            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                width: 15%;
            }
            &:nth-child(1),
            &:nth-child(5) {
                width: 20%;
            }
            &:last-child {
                width: 15%;
                text-align: center;
            }
        }
    }
    // .pax-info__row {
    //   display: inline-block;
    // }
    .pax-info {
        &__passport, &__nationality, &__issued, &__expiry, &__mobile,&__email,&__meal-type,&__nominee-name,&__nominee-relation,&__past-illness,&__family-relationship,&__pan-card,&__pan-checkbox,&__address,&__city,
		&__pincode,&__state,&__district {
            float: left;
            padding: $base-padding/10;
            min-height: 1px;
        }
        &__passport {
            width: 20%;
        }
        &__nationality,
        &__issued,
        &__expiry,
        &__email,
        &__meal-type,
        &__nominee-name,
        &__nominee-relation,
        &__past-illness,
        &__family-relationship,
        &__address,
        &__city,
        &__pan-card,
        &__pan-checkbox,
        &__checkbox,
		&__pincode,
		&__state,
		&__district {
            width: 20%;
        }
        &__expiry {
            width: 20%;
            label {
                display: none;
            }
            .input-group .input-group-btn {
                display: table-cell;
                >button {
                    padding: 0.1rem 0.80rem;
                }
            }
        }
        &__checkbox {
        	float:right;
        	text-align:right;
        	.checkbox-component{
        	    font-size: 0.875rem;
        	}
        }
    }
}
.pax-info {
    @include create-main-wrapper;
    @include create-row-card;
    @include create-row-card-header;
    @include create-card-content;
    &_card {
        box-shadow: $row-shadow-color;
        padding-bottom: 0.9375rem;
        &_extra {
            border-bottom: 1px solid #087dc2;
            padding-bottom: 0.9375rem;
        }
        &_header {
            text-transform: uppercase;
            color: $brand-color-2;
            padding-left: 0px;
        }
    }
    //padding: 0.1rem 0.80rem;
    &__header {
        text-transform: uppercase;
        width: 100%;
        content: '';
        display: table;
        &_pax {
            float: left;
            width: 20%;
			min-height: 1px;
        }
        &_title {
            float: left;
            width: 20%;
            padding-right: 0.1875rem;
            padding-top: 0.1875rem;
            padding-bottom: 0.1875rem;
            padding-left: 0.1875rem;
        }
        &_first-name {
            width: 20%;
            float: left;
            padding: 0.1875rem;
        }
        &_last-name {
            width: 20%;
            float: left;
            padding: 0.1875rem;
        }
        &_dob {
            width: 20%;
            float: left;
            padding: 0.1875rem;
        }
        &_gender {
            display: none;
        }
		&_nominee-name,
		&_nominee-relation,
		&_past-illness,
		&_family-relationship{
			width: 20%;
            float: left;
            padding: 0.1875rem;
		}
    }
    &-card__header{
    	background:$light;
    }
}
.text-right {
    text-align: right;
}
.hold-pnr-dark{
	font-weight: bold;
    color: black;
}
.filter {
  padding: $base-padding/2;
  background-color: $light;
  margin-bottom: $base-margin/2;

  // border-radius: $border-radius-small;
  box-shadow: 0 0 15px rgba(0, 0, 0, .25);

  // @include box-shadow(0 0 2px rgba(0,0,0,.25));
  &__reset-all {
    @include clearfix();
    text-transform: uppercase;
    color: $dark;
    height:20px;
  }
  &__reset {
    background: transparent none repeat scroll 0 0;
    border: none;
    cursor: pointer;
    float: right;
    text-transform: uppercase;
    color: $filter-reset-color;
    &:hover {
      color: $filter-reset-hover-color;
    }
  }
  &__section {
    @include clearfix();
    
    &_input {
    	> input {
    		width: 100%;
		}
    }
    &_btnsearch{
		color: $brand-color-2;
    	font-weight: 600;
    	background-color: $brand-color-3;
    	float: right;
    }
	&_checkbox{
		float: left;
    	width: 15%;
	}
	&_filter-label{
		float: left;
    	width: 70%;
    	padding-top: .375rem;
		text-transform: uppercase;
	}
  }
  &__searchBar{
  	> input {
		width: 100%;
	}
	  	padding-bottom: 1rem;
  }
  &__header {
    font-weight: 500;
    color: $filter-header-color;
    text-transform: uppercase;
    padding-bottom: 6px;
    margin-bottom: 12px;
    display: block;
    border-bottom: 1px solid $brand-color-4;

    &_reset {
      background: transparent none repeat scroll 0 0;
      border: none;
      cursor: pointer;
      float: right;
      text-transform: uppercase;
      color: $filter-reset-color;
      &:hover {
        color: $filter-reset-hover-color;
      }
    }
  }
  &__textRange {
    @include clearfix;
    padding-top: $base-padding/2;
    width: 100%;
    margin-bottom: $base-margin/2;
    font-family: $font-family-number;
    &_right {
      float: right;
      > input {
        max-width: 50px;
      }
    }
    &_left {
      float: left;
      > input {
        max-width: 50px;
      }
    }
  }
  &__checkboxSelect {
    display: block;
    input {
      vertical-align: middle;
	  margin-right: 3px;
      margin-left: 3px;
    }
    li {
     
      margin-bottom: 5px;
      text-transform: uppercase;
      vertical-align: top;
      @include clearfix;
     .filter-data {
      @include clearfix;
      &__checkbox {
      float:left;
      width: 15%;
      }
      &__data , &__transit-data {
 		float:left;
 		width:75%;

          &_filter-label {
            float: left;
            word-spacing: normal;
            width: 100%;

          }

      	>label{
		    overflow: hidden;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    float: left;
		    word-spacing: normal;
		    width:100%;
      }
      }
      &__count {
    	width:15%;
    	float:right;
    	text-align:right;
    	font-family: $font-family-number;
      }
      &__label{
      	color: $filter-header-color;
      	margin-right: 1.825rem;
      }
      &__transit-data{
      	margin-bottom: 5%;
      	width: 90%;
      	&_airport-details {
      	    width: 90%;
    		float: left;
      	} 
      	&_airport-name {
    		@include  text-overflow();
    		width: 85%;
      		float: right;
    		word-spacing: normal;
      	} 
      	&_airport-count {
         	width: 5%;
    		float: right;
    		text-align: right;
    		font-family: $font-family-number;
      	}
      }
      }
      
    }
  }
}
.reset-all__button {
  @extend .filter__reset;

  //color:$filter-text-color;
}
.result__label {
  font-weight: 700;
  padding: $base-padding/5;
  background-color: $filter-result-background-color;
  color: $filter-result-label-color;
  margin-bottom: $base-margin/2;
  .result__count {
    font-size: 1.5rem;
    margin-right: $base-margin/6;
    line-height: 1.5rem;
    vertical-align: bottom;
    font-weight: 400;
    font-family: $font-family-number;
  }

  .result__text {}
	}

.checkbox-filter{
    max-height: 20rem;
    overflow: auto;
}
.filter-label {
  &__checkbox {
    width: 5%;
    margin: 0;
    padding: 0;
    float: left;

    >input{
      margin: 0;
    }
  }

  &__text {
    width: 90%;
    margin: 0;
    padding: 0;
    padding-left: 0.75rem;
    float: left;
  }
}